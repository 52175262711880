/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import GoogleIcon from 'mdi-react/GoogleIcon';
import Styles from './LoginCard.scss';

@connect(state => ({
    loginErrorMessage: state.authentication.errorMessage,
}), {})
export default class extends React.PureComponent<{
    loginErrorMessage?: string,
}, {}> {
    render() {
        return (
            <div className={Styles.loginCard}>
                <div className={Styles.loginCardHeader}>
                    Crux Documentation Portal
                </div>
                <div className={Styles.loginCardButtonRow}>
                    <a className={Styles.loginCardButton} href='/oauth/google'>
                        <GoogleIcon size={18} />
                        <span className={Styles.loginCardButtonText}>SIGN IN WITH GOOGLE</span>
                    </a>
                </div>
                {/* TODO: temporarily displaying the error until wireframes are done and we decide how we are handling errors */}
                <div>
                    {this.props.loginErrorMessage}
                </div>
            </div>
        );
    }
}
