/* @flow */
import * as React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router';
import ErrorPage from 'shared/ErrorPage/ErrorPage';
import GeneralUtil from 'utils/GeneralUtil';
import Navbar from 'shared/Navbar/Navbar';
import Styles from './RouterDisplay.scss';
import qs from 'qs';
import webStorage from 'data/webStorage';
import type { RouteConfig } from '../RouterTypes';

// NOTE: Avoid modifiying this file, configuration should occur in Router.jsx, if you need additional functionality be *very* careful

@withRouter
@connect(state => ({
    isLoggedIn: state.authentication.status === 'completed',
})) // Don't connect other properties to RouterDisplay, it rerenders the entire application
export default class extends React.PureComponent<{
    isLoggedIn?: boolean,
    location: RouteLocation,
    history: RouteHistory,
    config: { defaultPublicPath: string, defaultAuthenticatedPath: string },
    routeConfigs: Array<RouteConfig>,
}, {}> {
    currentRouteProps: ?{|
        routeHistory: RouteHistory,
        routeLocation: RouteLocation,
        routeMatch: RouteMatch,
        routeQuery: RouteQuery
    |};

    matchedRouteConfig: ?RouteConfig;
    location: ?RouteLocation;
    match: ?RouteMatch;
    queryUpdaters: { [string]: RouteQueryUpdater } = { };
    queries: { [string]: RouteQuery } = { };

    setTitle = (title?: string) => {
        if (title && title.length) {
            document.title = `Crux - ${title}`;
        } else {
            document.title = 'Crux';
        }
    }

    getQueryUpdater = (associatedRouteConfig: RouteConfig) => {
        if (!this.queryUpdaters[associatedRouteConfig.path]) {
            this.queryUpdaters[associatedRouteConfig.path] = (values, parameters) => {
                const cleanedValues = { ...values };
                const { currentRouteProps } = this;
                let updateNeeded = false;
                let existingValues = {};

                if (currentRouteProps && currentRouteProps.routeQuery) {
                    existingValues = currentRouteProps.routeQuery.values;
                }

                for (const key in cleanedValues) {
                    if (Object.prototype.hasOwnProperty.call(cleanedValues, key)) {
                        if (!associatedRouteConfig.queries ||
                            (!associatedRouteConfig.queries.has(key) && !associatedRouteConfig.allowDynamicQueries)
                        ) {
                            console.warn(`Could not update URL query parameters. Invalid query key: '${key}'. Query keys must be explicitly set in their corresponding RouteConfig.`);

                            return;
                        }

                        if (!GeneralUtil.isEqual(cleanedValues[key], existingValues[key])) {
                            updateNeeded = true;
                        }
                    }
                }

                if (!updateNeeded) return;

                const updatedValues = { ...existingValues, ...cleanedValues };

                for (const key in updatedValues) {
                    if (Object.prototype.hasOwnProperty.call(cleanedValues, key)) {
                        if (!updatedValues[key]) delete updatedValues[key];
                    }
                }

                const queryString = qs.stringify(updatedValues);
                let updatedPathName = this.props.location.pathname.replace(this.props.location.search, '');

                if (queryString.length) updatedPathName += `?${queryString}`;

                if (parameters && parameters.push) {
                    this.props.history.push(updatedPathName);
                } else {
                    this.props.history.replace(updatedPathName);
                }
            };
        }

        return this.queryUpdaters[associatedRouteConfig.path];
    }

    getQuery = (associatedRouteConfig: RouteConfig) => {
        const values = qs.parse(this.props.location.search || '', { ignoreQueryPrefix: true }) || {};

        if (!this.queries[associatedRouteConfig.path] || !GeneralUtil.isEqual(this.queries[associatedRouteConfig.path].values, values)) {
            this.queries[associatedRouteConfig.path] = {
                update: this.getQueryUpdater(associatedRouteConfig),
                values,
            };
        }

        return this.queries[associatedRouteConfig.path];
    }

    getLocation = (): RouteLocation => {
        if (
            !this.location ||
            this.location.pathname !== this.props.location.pathname ||
            this.location.search !== this.props.location.search
        ) {
            this.location = { ...this.props.location };
        } else {
            // Needs to mutate so that rendering is smart for PureComponents
            Object.assign(this.location, this.props.location);
        }

        if (!this.location) {
            console.warn('Unexpected missing route location. PureComponents will no longer work with the Router.');

            return this.props.location;
        }

        return this.location;
    }

    getMatch = (potentialRouteConfig: RouteConfig): ?RouteMatch => {
        const match = matchPath(this.props.location.pathname, { path: potentialRouteConfig.path, exact: true });

        if (!match) return;

        if (!this.match || !GeneralUtil.isEqual(this.match, match)) this.match = { ...match };

        return this.match;
    }

    matchRouteConfig = () => {
        for (let idx = 0; idx < this.props.routeConfigs.length; idx++) {
            const routeConfig = this.props.routeConfigs[idx];
            const match = this.getMatch(routeConfig);

            if (match) {
                this.currentRouteProps = {
                    routeHistory: this.props.history,
                    routeLocation: this.getLocation(),
                    routeMatch: match,
                    routeQuery: this.getQuery(routeConfig),
                };

                for (const key in this.currentRouteProps.routeQuery.values) {
                    if (Object.prototype.hasOwnProperty.call(this.currentRouteProps.routeQuery.values, key)) {
                        if (!routeConfig.queries || (!routeConfig.queries.has(key) && !routeConfig.allowDynamicQueries)) {
                            console.warn(`Invalid query key: '${key}'. Query keys must be explicitly set in their corresponding RouteConfig.`);
                        }
                    }
                }

                this.matchedRouteConfig = routeConfig;

                return;
            }

            this.matchedRouteConfig = null;
        }
    }

    render() {
        let authenticatedContent;
        let publicContent;

        this.matchRouteConfig();

        if (this.matchedRouteConfig && this.currentRouteProps) {
            const { matchedRouteConfig, currentRouteProps } = this;
            const MatchedComponent = matchedRouteConfig.component;
            const content = (<MatchedComponent key={matchedRouteConfig.path} {...currentRouteProps} />);

            if (matchedRouteConfig.params && matchedRouteConfig.params.authenticatedOnly) {
                if (!this.props.isLoggedIn) {
                    webStorage.store('redirectPath', currentRouteProps.routeLocation.pathname);

                    publicContent = (<Switch><Redirect from={currentRouteProps.routeMatch.path} to='/login' /></Switch>);
                } else {
                    authenticatedContent = content;

                    this.setTitle(matchedRouteConfig.title);
                }
            } else if ((!matchedRouteConfig.params || matchedRouteConfig.params.publicOnly) && this.props.isLoggedIn) {
                if (matchedRouteConfig.params && typeof matchedRouteConfig.params.publicOnly === 'object' && matchedRouteConfig.params.publicOnly.auto) {
                    const redirectPath = webStorage.retrieve('redirectPath') || this.props.config.defaultAuthenticatedPath;

                    webStorage.store('redirectPath', '');

                    publicContent = (<Switch><Redirect from={currentRouteProps.routeMatch.path} to={redirectPath} /></Switch>);
                } else {
                    publicContent = (
                        <Switch>
                            <Redirect from={currentRouteProps.routeMatch.path} to={`/${this.props.config.defaultAuthenticatedPath.replace(/^\//g, '')}`} />
                        </Switch>
                    );
                }
            } else {
                publicContent = content;

                this.setTitle(matchedRouteConfig.title);
            }
        } else if (!this.props.isLoggedIn) {
            // None of the routes defined in Router matched.
            // User is not logged in, therefore redirect to the login page.
            publicContent = (<Switch><Redirect from={this.props.location.pathname} to='/login' /></Switch>);

            webStorage.store('redirectPath', `${this.props.location.pathname}${this.props.location.search}`);
        } else {
            // Display error page since the user is now logged in.
            publicContent = (
                <ErrorPage
                    defaultPath={this.props.config.defaultAuthenticatedPath}
                    routeHistory={this.props.history}
                    type='404'
                />
            );
        }

        return (
            <div className={Styles.routerDisplay}>
                <div className={Styles.routerDisplayView}>
                    {authenticatedContent &&
                        <div className={Styles.routerDisplayAuthenticated}>
                            <div className={Styles.routerDisplayAuthenticatedHeader}>
                                <div className={Styles.routerDisplayAuthenticatedNav}>
                                    {this.currentRouteProps &&
                                    <Navbar
                                        matchedRouteConfig={this.matchedRouteConfig}
                                        query={this.currentRouteProps.routeQuery}
                                        routeHistory={this.currentRouteProps.routeHistory}
                                        routeMatch={this.currentRouteProps.routeMatch}
                                    />}
                                </div>
                            </div>
                            <div className={Styles.routerDisplayAuthenticatedContent}>
                                {authenticatedContent}
                            </div>
                        </div>
                    }
                    {publicContent}
                </div>
                <Switch>
                    {
                        this.props.isLoggedIn &&
                        this.props.config.defaultAuthenticatedPath &&
                        <Redirect exact from='/' to={`/${this.props.config.defaultAuthenticatedPath.replace(/^\//g, '')}`} />
                    }
                    {
                        !this.props.isLoggedIn &&
                        this.props.config.defaultPublicPath &&
                        <Redirect exact from='/' to={`/${this.props.config.defaultPublicPath.replace(/^\//g, '')}`} />
                    }
                </Switch>
            </div>
        );
    }
}
