/* @flow */
import * as React from 'react';
import { TextButton } from '@cruxinformatics/ui-shared-components';
import ErrorGraphic from './ErrorGraphic';
import Styles from './ErrorPage.scss';

export default class extends React.PureComponent<{
    defaultPath: string,
    routeHistory: RouteHistory,
    type: string,
}, {}> {
    returnHome = () => {
        this.props.routeHistory.push(this.props.defaultPath);
    };

    render() {
        return (
            <div className={Styles.view}>
                <ErrorGraphic hour={this.props.type[0]} minutes={this.props.type.slice(1)} />
                <div className={Styles.actionButtonWrapper}>
                    <TextButton
                        color='primary'
                        onClick={this.returnHome}
                        size='large'
                        text='Return Home'
                    />
                </div>
            </div>
        );
    }
}
