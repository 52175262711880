/* @flow */
import StringUtil from 'utils/StringUtil';
import classNames from 'classnames';

export default {
    combineClass: classNames,
    compose: (tokenList: Array<string>): string => {
        let className = '';

        tokenList.forEach((token, index) => {
            let modifiedToken = token;

            if (index !== 0) modifiedToken = StringUtil.capitalize(token);

            className += modifiedToken;
        });

        return className;
    },
    dupeModifyClass: (styles: {[string]: string}, baseClassKey: string, ...tokens: Array<?string>): string => {
        let classString = styles[baseClassKey];

        for (let idx = 0, len = tokens.length; idx < len; idx++) {
            const token = tokens[idx];

            if (token) {
                const modifiedClassKey = baseClassKey + StringUtil.capitalize(token);

                if (styles[modifiedClassKey]) classString = `${classString} ${styles[modifiedClassKey]}`;
            }
        }

        return classString;
    },
};
