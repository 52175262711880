/* @flow */
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';

function removeItemFromArray<T>(item: T, array: Array<T>) {
    const index = array.indexOf(item);

    if (index < 0) return;

    array.splice(index, 1);
}

export default {
    isEqual,
    cloneDeep,
    merge,
    shallowEqual: (
        objA: mixed,
        objB: mixed,
        options?: {|
            ignoreProperties?: Array<string>,
            onlyProperties ?: Array<string>,
            showLog ?: boolean
        |},
    ): boolean => {
        const parsedOptions = options || {};

        if (objA === objB) return true;

        if (
            typeof objA !== 'object' || objA === null ||
            typeof objB !== 'object' || objB === null
        ) return false;

        let keysA = Object.keys(objA);
        let keysB = Object.keys(objB);

        if (parsedOptions.ignoreProperties) {
            const { ignoreProperties } = parsedOptions;

            for (let idx = 0, len = ignoreProperties.length; idx < len; idx++) {
                removeItemFromArray(ignoreProperties[idx], keysA);

                removeItemFromArray(ignoreProperties[idx], keysB);
            }
        }

        if (parsedOptions.onlyProperties) {
            keysA = [...parsedOptions.onlyProperties];

            keysB = [...parsedOptions.onlyProperties];
        }

        if (keysA.length !== keysB.length) return false;

        const bHasOwnProperty = hasOwnProperty.bind(objB);

        for (let i = 0; i < keysA.length; i++) {
            if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
                if (parsedOptions.showLog) console.warn(`Shallow comparison failed equality on properties: ${keysA[i]} == ${keysB[i]}`, objA[keysA[i]], objB[keysB[i]]);

                return false;
            }
        }

        return true;
    },
};
