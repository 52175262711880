/* @flow */

declare type StorageKey = 'userToken';
declare type RedirectPath = 'redirectPath';
declare type Cookie = StorageKey | RedirectPath;

const domainKey: string = window.location.origin;
const buildKey = (baseKey: string) => `crux_portal_${baseKey}_${domainKey}`;

export default {
    store: (key: Cookie, value: string, persist: ?boolean) => {
        const prefixedKey = buildKey(key);

        if (persist) {
            window.localStorage.setItem(prefixedKey, value);
        } else {
            window.sessionStorage.setItem(prefixedKey, value);
        }
    },
    delete: (key: Cookie) => {
        const prefixedKey = buildKey(key);

        window.sessionStorage.removeItem(prefixedKey);

        window.localStorage.removeItem(prefixedKey);
    },
    retrieve: (key: Cookie): ?string => {
        const prefixedKey = buildKey(key);
        const value = window.sessionStorage.getItem(prefixedKey) || window.localStorage.getItem(prefixedKey);

        return value;
    },
};
