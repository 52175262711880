/* @flow */
import * as React from 'react';
import { IconButton, Navbar as Nav } from '@cruxinformatics/ui-shared-components';
import { useDispatch } from 'react-redux';
import LogoutIcon from 'icons/logout.svg';
import Styles from './Navbar.scss';

const Navbar = (props: Object) => {
    const { routeMatch } = props;
    const dispatch = useDispatch();
    // $FlowIgnore our version of flow does not support new react features
    const activeItemValue = React.useRef(routeMatch.url.split('/')[1]);
    const navbarItems = [
        {
            element: <img alt='Crux Logo' className={Styles.logo} src='/public/img/logo.svg' />,
            leftAlign: true,
            value: 'logo',
        },
        {
            element: (
                <IconButton
                    color='warning'
                    Icon={LogoutIcon}
                    onClick={logout}
                    size='standard'
                    svg
                    text='Logout'
                />
            ),
            value: 'logout',
        },
    ];

    function logout() {
        dispatch({ type: 'LOG_USER_OUT' });
    }

    return (
        <Nav
            activeItemValue={activeItemValue.current}
            items={navbarItems}
            theme='light'
        />
    );
};

export default Navbar;
