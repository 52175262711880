/* @flow */
import * as React from 'react';
import PortalUtil from 'utils/PortalUtil';
import Styles from './Portal.scss';

const Portal = (props: Object) => {
    const { routeMatch } = props;

    return (
        <div className={Styles.view}>
            <iframe
                className={Styles.frame}
                frameBorder='0'
                src={PortalUtil.getRemoteRelativeUrl(routeMatch.url)}
                title='crux-portal'
            />
        </div>
    );
};

export default Portal;
