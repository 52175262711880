/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import Styles from './Authorizer.scss';
import type { Action } from 'data/types';

@connect(state => ({
    hasFailed: state.authentication.status === 'failed',
}), {
    sendGoogleCode: code => ({ type: 'LOG_USER_IN_WITH_GOOGLE_CODE', code }),
    logout: () => ({ type: 'LOG_USER_OUT' }: Action),
})
export default class extends React.Component<{
    hasFailed: ?boolean,
    sendGoogleCode: (code: string) => ({ type: string, code: string }),
    logout: ?Function,
    routeMatch: RouteMatch,
    routeQuery: RouteQuery,
    routeHistory: RouteHistory
}, {}> {
    componentWillMount() {
        const authorizationType = this.props.routeMatch.params.type;

        if (this.props.hasFailed) {
            this.directToLogin();

            return;
        }

        if (this.props.logout) this.props.logout();

        if (authorizationType === 'google') {
            if (this.props.sendGoogleCode && this.props.routeQuery.values.code) {
                this.props.sendGoogleCode(this.props.routeQuery.values.code);
            } else {
                console.warn('Redux callback or query argument \'code\' is missing.');

                this.directToLogin();
            }
        } else {
            console.warn('Unrecognized authorization type:', authorizationType);

            this.directToLogin();
        }
    }

    componentDidUpdate() {
        if (this.props.hasFailed) this.directToLogin();
    }

    directToLogin() {
        this.props.routeHistory.replace('/login');
    }

    render() {
        return (
            <div className={Styles.view}>
                <div className={Styles.background} />
            </div>
        );
    }
}
