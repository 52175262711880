/* @flow */
import GeneralUtil from 'utils/GeneralUtil';
import type { State, Store } from 'data/types';

// $FlowIgnore: Flow doesn't acknowledge webpack black magic
const req = require.context('.', true, /\.js$/);
let initialObserverList = [];
let store;

req.keys().forEach((key) => {
    if (key === './observers.js') return;

    const observer = req(key).default;

    if (Array.isArray(observer)) {
        initialObserverList = [...initialObserverList, ...observer];
    } else {
        initialObserverList.push(observer);
    }
});

function applyObserver<T>(select: (State) => T, onChange?: ((Store, T) => any), atStart?: ((Store, T) => any)) {
    let currentState: T;

    function handleChange() {
        const nextState: T = select(store.getState());

        if (nextState === currentState) return;

        if (GeneralUtil.shallowEqual(nextState, currentState)) return;

        currentState = nextState;

        if (onChange) onChange(store, currentState);
    }

    if (atStart) atStart(store, select(store.getState()));

    if (onChange) {
        const unsubscribe = store.subscribe(handleChange);

        handleChange();

        return unsubscribe;
    }
}

export default {
    observe: (observableStore: Store) => {
        if (store) {
            console.warn('Observer application should occur once and only on one redux \'store\'.');

            return;
        }

        store = observableStore;

        initialObserverList.forEach((observerInfo) => {
            applyObserver(observerInfo.select, observerInfo.onChange, observerInfo.atStart || undefined);
        });
    },
    add: (observer: { select: *, onChange?: *, atStart?: * }) => {
        if (!store) {
            initialObserverList.push(observer);

            return;
        }

        applyObserver(observer.select, observer.onChange, observer.atStart);
    },
};
