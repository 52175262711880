/* @flow */
function getRemoteRelativeUrl(path: string) {
    const mapper = {
        '/resources': `${_ENV_.DOCS_STORE_ROUTE_PREFIX}${_ENV_.DOCS_STORE_INDEX_PATH}`,
    };

    return mapper[path];
}

export default {
    getRemoteRelativeUrl,
};
