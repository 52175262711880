/* @flow */
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Authorizer from 'foundational/Authenticator/Authorizer/Authorizer';
import Login from 'foundational/Authenticator/Login/Login';
import Portal from 'shared/Portal/Portal';
import RouterDisplay from './RouterDisplay/RouterDisplay';
import Splash from './Splash/Splash';
import type { RouteConfig, RouteConfigParams } from './RouterTypes';

const config = { defaultPublicPath: '/login', defaultAuthenticatedPath: '/resources' };
// should use children for any subroutes: https://reacttraining.com/react-router/web/api/Route/children-func
const publicRouteConfigs: Array<RouteConfig> = [{
    path: '/login',
    component: Login,
    params: { publicOnly: { auto: true } },
    title: 'Login',
}, {
    path: '/auth/:type(google)',
    component: Authorizer,
    params: { publicOnly: { auto: true } },
    title: 'Authorizing',
    queries: new Set(['code', 'scope']),
}];
const authenticatedRouteConfigs: Array<RouteConfig> = combineRouteConfigParams([{
    path: '/:view(resources)',
    component: Portal,
    title: 'Crux Documentation Portal',
    queries: new Set(['search', 'start', 'end']),
    allowDynamicQueries: true,
}], {
    authenticatedOnly: true,
    showNavbar: true,
});
const allRouteConfigs = [...publicRouteConfigs, ...authenticatedRouteConfigs];

// this allows us to apply a set of params to many routeConfigs with one function instead of doing it manually to each route
function combineRouteConfigParams(routeConfigs: Array<RouteConfig>, params: ?RouteConfigParams = {}): Array<RouteConfig> {
    return routeConfigs.map(routeConfig => ({
        ...routeConfig,
        params: {
            ...routeConfig.params,
            ...params,
        },
    }));
}

export default connect((state): { hydrating: boolean } => ({
    hydrating: state.hydration.status,
}))((props: { hydrating: boolean }) => {
    if (props.hydrating) return <Splash />;

    return (
        <BrowserRouter>
            {/* $FlowIgnore RouteDisplay is a special case where withRouter always needs to apply */}
            <RouterDisplay config={config} routeConfigs={allRouteConfigs} />
        </BrowserRouter>
    );
});
