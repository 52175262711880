/* @flow */
import { put, takeLatest } from 'redux-saga/effects';
import WebApiUtil from 'utils/WebApiUtil';
import type { Action } from 'data/types';
import type { Saga } from 'redux-saga';

export default takeLatest('LOG_USER_IN_WITH_TOKEN', function* (action: Action): Saga<void> {
    try {
        if (action.token) {
            const response: Response = yield WebApiUtil.post('auth/signin/token', { token: action.token }, {
                requiresToken: false,
            });
            const { data } = response;

            yield put(({ type: 'LOG_USER_IN_SUCCEEDED', account: data.account, token: data.token }: Action));
        } else {
            console.warn('action.token is missing in LOG_USER_IN_WITH_TOKEN.');
        }
    } catch (error) {
        console.warn(error);
        yield put(({ type: 'LOG_USER_IN_FAILED', error: { type: 'token', message: 'Your session has timed out. Please log in again to continue.' } }: Action));
    }
});
