/* @flow */
import * as React from 'react';
import LoginCard from './LoginCard/LoginCard';
import PageHeader from 'shared/PageHeader/PageHeader';
import Styles from './Login.scss';

export default class extends React.PureComponent<{}, {}> {
    state = {}

    render() {
        return (
            <div className={Styles.view}>
                <PageHeader className={Styles.logo} lightTheme />
                <div className={Styles.footer}>
                    <div className={Styles.footerText}>
                        Crux Informatics Inc.
                        <br />
                        © 2020 All rights reserved.
                    </div>
                </div>
                <div className={Styles.login}>
                    <LoginCard />
                </div>
            </div>
        );
    }
}
