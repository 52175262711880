/* @flow */
import { put, takeLatest } from 'redux-saga/effects';
import WebApiUtil from 'utils/WebApiUtil';
import type { Action } from 'data/types';
import type { Saga } from 'redux-saga';

export default takeLatest('REFRESH_TOKEN', function* (action: Action): Saga<void> {
    try {
        if (action.uid) {
            const response: Response = yield WebApiUtil.post('auth/user/token/refresh', { uid: action.uid });
            const { data } = response;

            yield put(({ type: 'REFRESH_TOKEN_SUCCEEDED', tokens: { token: data.newToken, customToken: data.newCustomtoken } }: Action));
        }
    } catch (error) {
        console.warn(error);
    }
});
