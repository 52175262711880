/* @flow */
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import Router from 'foundational/Router/Router';
import Store from 'data/store';
import Styles from './App.scss'; // eslint-disable-line no-unused-vars

let App;

if (_ENV_.NODE_ENV === 'development') {
    App = (
        <AppContainer>
            <Provider store={Store}>
                <Router />
            </Provider>
        </AppContainer>
    );
} else {
    App = (
        <Provider store={Store}>
            <Router />
        </Provider>
    );
}

ReactDOM.render(
    App,
    // $FlowIgnore: Flow doesn't recognize window grade document
    document.getElementById('main'),
);

// $FlowIgnore: Flow isn't aware of HMR
if (_ENV_.NODE_ENV === 'development' && module.hot) module.hot.accept();
