/* @flow */
import { put, takeLatest } from 'redux-saga/effects';
import WebApiUtil from 'utils/WebApiUtil';
import type { Action } from 'data/types';
import type { Saga } from 'redux-saga';

export default takeLatest('LOG_USER_IN_WITH_GOOGLE_CODE', function* (action: Action): Saga<void> {
    try {
        if (action.code) {
            const response: Response = yield WebApiUtil.post('auth/signin/google', { code: action.code }, {
                requiresToken: false,
            });
            const { data } = response;

            yield put(({ type: 'LOG_USER_IN_SUCCEEDED', account: data.account, token: data.token }: Action));
        } else {
            console.warn('action.code missing in LOG_USER_IN_WITH_GOOGLE_CODE.');
        }
    } catch (error) {
        const { response: { data } } = error;

        console.warn(error);

        yield put(({ type: 'LOG_USER_IN_FAILED', error: data }: Action));
    }
});
