/* @flow */
import * as React from 'react';
import { Link } from 'react-router-dom';
import StyleUtil from 'utils/StyleUtil';
import Styles from './PageHeader.scss';

export default class extends React.PureComponent<{
    title?: string,
    className?: string,
    lightTheme?: boolean,
}, {}> {
    render() {
        return (
            <div className={StyleUtil.combineClass(Styles.view, this.props.className)}>
                <div className={Styles.viewHeader}>
                    <Link to='/'>
                        <img alt='Crux Logo' className={Styles.logo} src={this.props.lightTheme ? '/public/img/logo-white.svg' : '/public/img/logo.svg'} />
                    </Link>
                    {this.props.title && <div className={Styles.viewHeaderTitle}>{this.props.title}</div>}
                </div>
            </div>
        );
    }
}
