/* @flow */
import { call } from 'redux-saga/effects';
import GeneralUtil from 'utils/GeneralUtil';
import Store from 'data/store';
import axios from 'axios';
import type { AxiosXHRConfigBase } from 'axios';

const defaultParams = {
    requiresToken: true,
    config: {},
};

const getToken = () => {
    const { authentication } = Store.getState();

    if (authentication.info && authentication.info.stsTokenManager) {
        return authentication.info.stsTokenManager.accessToken;
    }
};

const standardizeParams = (params?: *) => {
    const standardizedParams = GeneralUtil.merge(GeneralUtil.cloneDeep(defaultParams), params);

    if (standardizedParams.requiresToken) {
        const token = getToken();

        if (token) {
            if (!standardizedParams.config.headers) standardizedParams.config.headers = {};

            standardizedParams.config.headers.authorization = `Bearer ${token}`;
        } else {
            console.warn('Unable to make authenticated API request. Account token is missing.');
        }
    }

    return standardizedParams;
};

const generateCreateUpdateCall = baseCall => (endpoint: string, data?: ?any, params?: {
    config?: AxiosXHRConfigBase<*>
}) => {
    const standardizedParams = standardizeParams(params);

    return call(baseCall, `/auth-gw-api/${endpoint}`, data, standardizedParams.config);
};

const generateReadDeleteCall = baseCall => (endpoint: string, params?: {
    config?: AxiosXHRConfigBase<*>
}) => {
    const standardizedParams = standardizeParams(params);

    // $FlowIgnore: Incomplete Axios flow definition, maybe try fixing when time available
    return call(baseCall, `/auth-gw-api/${endpoint}`, standardizedParams.config);
};

export default {
    post: generateCreateUpdateCall(axios.post),
    put: generateCreateUpdateCall(axios.put),
    get: generateReadDeleteCall(axios.get),
    delete: generateReadDeleteCall(axios.delete),
};
