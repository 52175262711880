/* @flow */
import { combineReducers } from 'redux';
import type { Action, Reducer } from 'data/types';

export default combineReducers({
    status: ((state: boolean = false, action: Action) => {
        switch (action.type) {
            case 'LOG_USER_IN_SUCCEEDED':
            case 'LOG_USER_IN_FAILED':
            case 'LOG_USER_OUT':
                return false;
            case 'LOG_USER_IN_WITH_GOOGLE_CODE':
            case 'LOG_USER_IN_WITH_TOKEN':
                return true;
            default:
                return state;
        }
    }: Reducer<*>),
});
