/* @flow */
import { all } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

// $FlowIgnore: Flow doesn't acknowledge webpack black magic
const req = require.context('.', true, /\.js$/);
let sagaList = [];

req.keys().forEach((key) => {
    if (key === './sagas.js') return;
    if (key.includes('associations') || key.includes('workflows')) return;

    const saga = req(key).default;

    if (Array.isArray(saga)) {
        sagaList = [...sagaList, ...saga];
    } else {
        sagaList.push(saga);
    }
});

export default function* sagas(): Saga<void> {
    yield all([
        ...sagaList,
    ]);
}
