/* @flow */

import {
    applyMiddleware, combineReducers, compose, createStore,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import observers from 'data/observers/observers';
import reducers from 'data/reducers/reducers';
import sagas from 'data/sagas/sagas';
import type { Action, Store } from 'data/types';

const sagaMiddleware = createSagaMiddleware();
const appReducer = combineReducers(reducers);

const rootReducer = (state: any, action: Action) => {
    let newState = state;

    if (action.type === 'LOG_USER_OUT') {
        newState = undefined;
    }

    return appReducer(newState, action);
};

// Ignoring the following line from linter, __REDUX_DEVTOOLS_EXTENSION_COMPOSE__
// comes from the docs but doesn't pass the linter because of the underscores
/* eslint-disable */
const composeEnhancers: Function = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function) || compose;
/* eslint-enable */
const store: Store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(sagas, store.dispatch);

observers.observe(store);

export default store;
