/* @flow */
let tokenRefresh;
let token;
const tokenRefreshObserver: Observer<*> = {
    select: state => state.authentication.info,
    onChange: (store, account) => {
        if (tokenRefresh) clearTimeout(tokenRefresh);

        if (!account || !account.stsTokenManager) return;

        if (!token || token !== account.stsTokenManager.accessToken) {
            token = account.stsTokenManager.accessToken;
            tokenRefresh = setTimeout(() => {
                store.dispatch({ type: 'REFRESH_TOKEN', uid: account.uid });
            }, account.stsTokenManager.expirationTime - Date.now());
        }
    },
};

export default tokenRefreshObserver;
