/* @flow */
import { combineReducers } from 'redux';
import type { Action, Reducer } from 'data/types';

export default combineReducers({
    status: ((state: ('idle' | 'loading' | 'completed' | 'failed') = 'idle', action: Action) => {
        switch (action.type) {
            case 'LOG_USER_OUT':
                return 'idle';
            case 'LOG_USER_IN_WITH_GOOGLE_CODE':
            case 'LOG_USER_IN_WITH_TOKEN':
                return 'loading';
            case 'LOG_USER_IN_SUCCEEDED':
            case 'REFRESH_TOKEN_SUCCEEDED':
                return 'completed';
            case 'LOG_USER_IN_FAILED':
                return 'failed';
            default:
                return state;
        }
    }: Reducer<*>),
    info: ((state: (Object | null) = null, action: Action) => {
        switch (action.type) {
            case 'LOG_USER_OUT':
                return null;
            case 'LOG_USER_IN_SUCCEEDED':
                return action.account;
            case 'REFRESH_TOKEN_SUCCEEDED':
                if (state && state.stsTokenManager) {
                    return {
                        ...state,
                        stsTokenManager: {
                            ...state.stsTokenManager,
                            accessToken: action.tokens.token,
                            expirationTime: Date.now() + 3600000,
                        },
                    };
                }

                if (!state) {
                    return null;
                }
                console.warn('Unable to refresh token for absent user.');

                return { ...state };
            default:
                return state;
        }
    }: Reducer<*>),
    errorMessage: ((state: string = '', action: Action) => {
        switch (action.type) {
            case 'LOG_USER_IN_FAILED':
                return action.error.message;
            case 'LOG_USER_IN_SUCCEEDED':
            case 'LOG_USER_OUT':
            case 'CLEAR_LOG_IN_ERROR':
                return '';
            default:
                return state;
        }
    }: Reducer<*>),
    token: ((state: ?string = null, action: Action) => {
        switch (action.type) {
            case 'LOG_USER_OUT':
                return null;
            case 'LOG_USER_IN_SUCCEEDED':
                return action.token;
            case 'REFRESH_TOKEN_SUCCEEDED':
                return action.tokens.customToken;
            default:
                return state;
        }
    }: Reducer<*>),
});
