/* @flow */
import webStorage from 'data/webStorage';

const tokenObserver: Observer<*> = {
    select: state => state.authentication.token,
    atStart: (store, token) => {
        if (token) return;

        const isUserLoggedIn = store.getState().authentication.status === 'completed';

        if (isUserLoggedIn) return;

        const storedToken = webStorage.retrieve('userToken');

        if (storedToken) store.dispatch({ type: 'LOG_USER_IN_WITH_TOKEN', token: storedToken });
    },
    onChange: (store, token) => {
        if (token && typeof token === 'string') {
            webStorage.store('userToken', token, true);
        } else {
            webStorage.delete('userToken');
        }
    },
};

export default tokenObserver;
