/* @flow */
import * as React from 'react';

export default class extends React.PureComponent<{
    hour: string,
    minutes: string,
}, {}> {
    render() {
        return (
            <svg height={560} width={849}>
                <title>Error page</title>
                <defs>
                    <style>
                        {
                            "@font-face{font-family:'alarm';font-style:normal;font-weight:400;src:url(/public/font/alarm_clock.ttf)}"
                        }
                    </style>
                    <linearGradient
                        id='linearGradient-1'
                        x1='84.466%'
                        x2='11.229%'
                        y1='0%'
                        y2='93.771%'
                    >
                        <stop offset='0%' stopColor='#F0F0F0' />
                        <stop offset='100%' stopColor='#FDFDFD' />
                    </linearGradient>
                    <linearGradient
                        id='linearGradient-2'
                        x1='49.12%'
                        x2='50.085%'
                        y1='66.908%'
                        y2='48.372%'
                    >
                        <stop offset='0%' stopColor='#CCC' />
                        <stop offset='100%' stopColor='#969696' />
                    </linearGradient>
                    <linearGradient
                        id='linearGradient-3'
                        x1='47.881%'
                        x2='44.621%'
                        y1='-29.752%'
                        y2='-212.2%'
                    >
                        <stop offset='0%' stopColor='#CCC' />
                        <stop offset='100%' stopColor='#969696' />
                    </linearGradient>
                    <linearGradient
                        id='linearGradient-4'
                        x1='0%'
                        x2='100%'
                        y1='50%'
                        y2='50%'
                    >
                        <stop offset='25%' stopColor='#262626' />
                        <stop offset='100%' />
                    </linearGradient>
                    <linearGradient
                        id='linearGradient-5'
                        x1='61.061%'
                        x2='78.61%'
                        y1='34.808%'
                        y2='.361%'
                    >
                        <stop offset='0%' stopColor='#BFBFBF' />
                        <stop offset='4.7%' stopColor='#BDBDBD' />
                        <stop offset='65%' stopColor='#A0A0A0' />
                        <stop offset='100%' stopColor='#969696' />
                    </linearGradient>
                    <linearGradient
                        id='linearGradient-6'
                        x1='46.188%'
                        x2='26.157%'
                        y1='34.035%'
                        y2='-49.861%'
                    >
                        <stop offset='0%' stopColor='#E6E6E6' />
                        <stop offset='100%' stopColor='#BDBDBD' />
                    </linearGradient>
                    <linearGradient
                        id='linearGradient-7'
                        x1='34.987%'
                        x2='88.802%'
                        y1='79.817%'
                        y2='-7.702%'
                    >
                        <stop offset='0%' stopColor='#CCC' />
                        <stop offset='100%' stopColor='#969696' />
                    </linearGradient>
                    <linearGradient
                        id='linearGradient-8'
                        x1='52.401%'
                        x2='32.369%'
                        y1='59.646%'
                        y2='-24.249%'
                    >
                        <stop offset='0%' stopColor='#E6E6E6' />
                        <stop offset='100%' stopColor='#BDBDBD' />
                    </linearGradient>
                    <linearGradient
                        id='linearGradient-9'
                        x1='47.673%'
                        x2='51.176%'
                        y1='95.097%'
                        y2='34.188%'
                    >
                        <stop offset='0%' stopColor='#D1D1D1' />
                        <stop offset='48.9%' stopColor='#C2C2C2' />
                        <stop offset='100%' stopColor='#B8B8B8' />
                    </linearGradient>
                    <linearGradient
                        id='linearGradient-10'
                        x1='6.376%'
                        x2='52.11%'
                        y1='82.231%'
                        y2='13.764%'
                    >
                        <stop offset='0%' stopColor='#525252' />
                        <stop offset='100%' stopColor='#2B2B2B' />
                    </linearGradient>
                    <linearGradient
                        id='linearGradient-11'
                        x1='52.535%'
                        x2='47.496%'
                        y1='10.317%'
                        y2='79.917%'
                    >
                        <stop offset='0%' stopColor='#E6945E' />
                        <stop offset='88.2%' stopColor='#F5BA89' />
                    </linearGradient>
                    <linearGradient
                        id='linearGradient-12'
                        x1='-.194%'
                        x2='102.296%'
                        y1='33.541%'
                        y2='65.841%'
                    >
                        <stop offset='0%' stopColor='#452A14' />
                        <stop offset='100%' stopColor='#7D491E' />
                    </linearGradient>
                    <linearGradient
                        id='linearGradient-13'
                        x1='77.556%'
                        x2='18.106%'
                        y1='58.281%'
                        y2='44.563%'
                    >
                        <stop offset='0%' stopColor='#4A4A4A' />
                        <stop offset='100%' stopColor='#353535' />
                    </linearGradient>
                    <linearGradient
                        id='linearGradient-14'
                        x1='50%'
                        x2='50%'
                        y1='1.795%'
                        y2='60.558%'
                    >
                        <stop offset='0%' stopColor='#636363' />
                        <stop offset='4.2%' stopColor='#5F5F5F' />
                        <stop offset='44%' stopColor='#3F3F3F' />
                        <stop offset='77.3%' stopColor='#2B2B2B' />
                        <stop offset='100%' stopColor='#242424' />
                    </linearGradient>
                    <linearGradient
                        gradientTransform='rotate(3.425 2368.16 -2754.261)'
                        gradientUnits='userSpaceOnUse'
                        id='a'
                        x1={800.847}
                        x2={861.246}
                        y1={396.514}
                        y2={230.57}
                    >
                        <stop offset={0} stopColor='#ffecc7' stopOpacity={0} />
                        <stop offset={1} stopColor='#ffecc7' />
                    </linearGradient>
                </defs>
                <g
                    fill='none'
                    fillRule='evenodd'
                    id='group1'
                    stroke='none'
                    strokeWidth={1}
                >
                    <g id='errorPage' transform='translate(-295 -294)'>
                        <g id='desk' transform='translate(295 294)'>
                            <path
                                d='M373.11 222H507L184.502 559 90 556.366z'
                                fill='url(#linearGradient-1)'
                                id='rectangle'
                            />
                            <ellipse
                                cx={399.38}
                                cy={557.826}
                                fill='#000'
                                id='Oval123'
                                opacity={0.2}
                                rx={399.38}
                                ry={1.863}
                            />
                            <ellipse
                                cx={399.38}
                                cy={2.826}
                                fill='#000'
                                id='Oval'
                                opacity={0.2}
                                rx={399.38}
                                ry={1.863}
                            />
                            <path
                                d='M357.674 345.737l-12.248 54.187h-31.162l28.527-54.187z'
                                fill='url(#linearGradient-2)'
                                id='path1'
                            />
                            <path
                                d='M395.349 395.576l71.783 3.26v.932h-71.783z'
                                fill='url(#linearGradient-3)'
                                id='path2'
                            />
                            <path
                                d='M274.729 359.866c-.155 5.9 4.496 10.713 10.387 10.868h.465l134.73-1.708c6.2 0 11.007-4.347 11.162-10.558l.93-3.57.93-71.422c0-6.21-4.96-11.334-11.162-11.49l-136.59-1.707c-6.201 0-11.162 4.813-11.317 11.024v72.352l.465 6.21z'
                                fill='#363636'
                                id='path3'
                            />
                            <path
                                d='M278.915 354.897l2.48-79.805 146.977 2.019v78.873c-11.938-.466-90.388-.776-149.457-1.087z'
                                fill='#F7F7F7'
                                id='path4'
                            />
                            <path
                                d='M484.496 400.079c2.48-2.795 6.047-4.192 9.768-4.192 6.356 0 13.023 4.192 13.023 4.192h-22.79z'
                                fill='#D4D4D4'
                                id='path5'
                            />
                            <polygon
                                fill='url(#a)'
                                id='lamplight'
                                points='646.519 119, 513 248.509, 668.392 317.681, 703.223 135.117, 646.519 119'
                            />
                            <path
                                d='M312.558 396.663h74.574v3.26h-76.28z'
                                fill='#949494'
                                id='path6'
                            />
                            <path
                                d='M285.581 371.045l135.194-1.242c6.202-.156 11.318-5.124 11.473-11.335l.155-3.726-158.14-1.242v6.366c0 6.21 5.117 11.334 11.318 11.179-.155 0-.155 0 0 0z'
                                fill='#E0E0E0'
                                id='path7'
                            />
                            <path
                                d='M350.233 360.176h11.318v2.484h-11.318z'
                                fill='#363636'
                                id='rectangle1'
                            />
                            <path
                                d='M293.023 329.279h80v1h-80zM293.023 314.684h80v1h-80zM293.023 299.934h80v1h-80zM293.023 285.184h80v1h-80z'
                                fill='#DEDEDE'
                                id='rectangle2'
                            />
                            <path
                                d='M312.868 344.184h-10.077v-34.779l9.612.621z'
                                fill='#4D4D4D'
                                id='path8'
                            />
                            <path
                                d='M328.837 344.184h-10.232V323.69l9.767.622z'
                                fill='#474747'
                                id='path9'
                            />
                            <path
                                d='M344.651 344.184H334.42v-30.897l9.767-1.398z'
                                fill='#909090'
                                id='path10'
                            />
                            <path
                                d='M360.465 344.184h-10.077v-54.963H360z'
                                fill='#DB1F28'
                                id='path11'
                            />
                            <path
                                d='M297.054 337.974l-.93-1.087 10.233-7.608 11.938 3.726 15.814-27.637 10.232 3.26 5.581-7.141 8.062-2.95 8.838-12.887 1.085.776-8.992 13.198-8.217 3.105-5.737 7.453-10.077-3.106-15.97 27.482-12.402-3.726z'
                                fill='url(#linearGradient-4)'
                                id='path12'
                            />
                            <path
                                d='M293.023 344.029h80v1h-80z'
                                fill='#A1A1A1'
                                id='rectangle3'
                            />
                            <path
                                d='M417.52 291.084H385.89v-4.968l31.628 1.242z'
                                fill='#BABABA'
                                id='path13'
                            />
                            <path
                                d='M412.868 302.884h-26.977v-5.123l26.977 1.242zM420.465 314.529h-34.574v-5.124l34.574 1.242zM414.109 326.174H385.89v-5.124l28.218 1.242zM409.767 337.818h-23.876v-4.968l23.876 1.242z'
                                fill='#D9D9D9'
                                id='path14'
                            />
                            <path
                                d='M464.496 266.397h-.465l-1.24 89.587h1.705s1.24-85.705 0-89.587z'
                                fill='#BDBDBD'
                                id='path15'
                            />
                            <path
                                d='M464.496 266.397h80.155v89.587h-80.155z'
                                fill='#F0F0F0'
                                id='rectangle5'
                            />
                            <path
                                d='M523.566 293.568l-1.55 48.753h22.635v-48.753z'
                                fill='#E0E0E0'
                                id='path16'
                            />
                            <path
                                d='M464.496 292.947h80.155v1.242h-80.155zM464.496 305.213h80.155v1.242h-80.155zM464.496 317.324h80.155v1.242h-80.155zM464.496 329.589h80.155v1.242h-80.155z'
                                fill='#CCC'
                                id='rectangle6'
                            />
                            <path
                                d='M510.698 293.568h1.085v48.753h-1.085z'
                                fill='#CCC'
                                id='rectangle7'
                            />
                            <path
                                d='M498.623 317.523l48.752-.002v1.085l-48.752.002z'
                                fill='#CCC'
                                id='rectangle8'
                                transform='rotate(-88.599 523 318.064)'
                            />
                            <path
                                d='M533.333 293.568h1.085v48.753h-1.085z'
                                fill='#CCC'
                                id='rectangle9'
                            />
                            <path
                                d='M464.496 341.7h80.155v1.242h-80.155z'
                                fill='#CCC'
                                id='rectangle10'
                            />
                            <path
                                d='M499.833 293.566h1.085v48.75h-1.086z'
                                fill='#CCC'
                                id='rectangle11'
                                transform='rotate(-.701 500.375 317.941)'
                            />
                            <path
                                d='M488.372 342.321h-1.085l.93-48.753h1.085z'
                                fill='#CCC'
                                id='path17'
                            />
                            <path
                                d='M475.335 293.565h1.086l-.001 49.838h-1.086z'
                                fill='#CCC'
                                id='rectangle12'
                                transform='rotate(-.68 475.878 318.484)'
                            />
                            <path
                                d='M471.473 295.587h2.171v1.242h-2.171zM484.341 295.587h2.171v1.242h-2.171zM496.589 295.587h2.171v1.242h-2.171zM507.442 295.587h2.171v1.242h-2.171zM519.845 295.587h2.171v1.242h-2.171zM529.767 295.587h2.171v1.242h-2.171zM541.085 295.587h2.171v1.242h-2.171zM471.473 307.697h2.171v1.242h-2.171zM484.341 307.697h2.171v1.242h-2.171zM496.589 307.697h2.171v1.242h-2.171zM507.442 307.697h2.171v1.242h-2.171zM519.845 307.697h2.171v1.242h-2.171zM529.767 307.697h2.171v1.242h-2.171zM541.085 307.697h2.171v1.242h-2.171zM471.473 319.808h2.171v1.242h-2.171zM484.341 319.808h2.171v1.242h-2.171zM496.589 319.808h2.171v1.242h-2.171zM507.442 319.808h2.171v1.242h-2.171zM519.225 319.808h2.171v1.242h-2.171zM529.767 319.808h2.171v1.242h-2.171zM541.085 319.808h2.171v1.242h-2.171zM471.628 331.763h2.171v1.242h-2.171zM484.496 331.763h2.171v1.242h-2.171zM496.589 331.763h2.171v1.242h-2.171zM507.597 331.763h2.171v1.242h-2.171zM519.38 331.763h2.171v1.242h-2.171zM529.767 331.763h2.171v1.242h-2.171zM541.24 331.763h2.171v1.242h-2.171z'
                                fill='#4F4F4F'
                                id='rectangle13'
                            />
                            <path
                                d='M464.496 266.397l.31 2.795 80.93.31-1.085-3.105z'
                                fill='#D6D6D6'
                                id='path18'
                            />
                            <path
                                d='M570.078 398.371l-.466-1.397.93-.932-.93-1.087.93-1.087-.93-9.16.93-2.95v-1.708l-.93-1.553.93-4.192h57.055l.155 3.882 1.085 2.018-.93.932.93 1.087-.465 11.023v.777l.31 1.708-.775 1.242.775 1.397z'
                                fill='#BDBDBD'
                                id='path19'
                            />
                            <path
                                d='M628.372 394.024v-.777l.465-10.868-.93-1.087.93-.931-.93-2.019-.155-3.881h-26.357l.155 3.881 1.086 4.658-.776 2.484-.93 1.087.93 1.553.156 6.055v.466l-.31.931.62.466-.466.932.466 1.397h26.666l-1.085-1.397.775-1.242z'
                                fill='#E8E8E8'
                                id='path20'
                            />
                            <path
                                d='M578.14 394.024s20.62-.621 22.17 0c-2.946.155-22.17 0-22.17 0zM568.682 388.434s20.62-.62 22.17 0c-2.945.155-22.17 0-22.17 0zM571.163 395.11s14.728-.465 15.659 0c-2.17.156-15.66 0-15.66 0zM578.14 386.571s14.728-.466 15.658 0c-2.17.155-15.658 0-15.658 0zM575.504 377.41s14.729-.465 15.659 0c-2.17.156-15.66 0-15.66 0zM587.907 379.895s14.729-.466 15.659 0c-2.016.155-15.659 0-15.659 0zM613.333 394.179s14.729-.466 15.66 0c-2.171.155-15.66 0-15.66 0zM603.72 389.832s14.73-.466 15.66 0h-15.66zM610.698 377.255s14.728-.466 15.659 0h-15.66zM595.039 375.082s14.728-.466 15.659 0c-2.016.155-15.66 0-15.66 0z'
                                fill='#CCC'
                                id='path21'
                            />
                            <path
                                d='M613.953 383.932s14.73-.466 15.66 0c-2.016.155-15.66 0-15.66 0zM617.054 397.129s11.628-.466 12.558 0c-1.705.155-12.558 0-12.558 0zM599.38 395.266s11.628-.466 12.558 0c-1.705.155-12.558 0-12.558 0z'
                                fill='#BDBDBD'
                                id='path22'
                            />
                            <path
                                d='M571.938 396.663s18.14-.62 22.636-.31c-2.016.465-22.636.31-22.636.31z'
                                fill='#CCC'
                                id='path23'
                            />
                            <path
                                d='M226.357 403.805H356.59v15.371H226.357zM508.062 403.805h130.233v15.371H508.062z'
                                fill='#525252'
                                id='rectangle14'
                            />
                            <path
                                d='M497.674 412.655h78.76l1.86 143.774h-86.356z'
                                fill='url(#linearGradient-5)'
                                id='path24'
                            />
                            <path
                                d='M570.078 412.655h77.674l1.86 143.774h-77.674z'
                                fill='url(#linearGradient-6)'
                                id='path25'
                            />
                            <path
                                d='M644.496 415.916v25.308h-71.008v-25.308h71.008zm.775-.621h-72.403V442h72.403v-26.705zM644.496 445.26v54.808h-70.543l-.465-54.807h71.008zm.775-.62h-72.403l.465 56.205h71.783v-56.206h.155zM644.961 504.26l.31 47.977H574.73l-.31-47.976h70.542zm.62-.776h-71.783l.31 49.219h71.783l-.31-49.219z'
                                fill='#A1A1A1'
                                fillRule='nonzero'
                                id='shape1'
                            />
                            <path
                                d='M595.349 415.295s15.504 8.694 26.201 0H595.35zM595.349 444.64s15.504 8.694 26.201 0H595.35zM595.349 503.484s15.504 8.695 26.201 0H595.35z'
                                fill='#525252'
                                id='path26'
                            />
                            <path
                                d='M217.674 412.655h78.76l1.86 143.774h-86.356z'
                                fill='url(#linearGradient-7)'
                                id='path27'
                            />
                            <path
                                d='M290.078 412.655h77.674l1.86 143.774h-77.519z'
                                fill='url(#linearGradient-8)'
                                id='path28'
                            />
                            <path
                                d='M364.496 415.916v25.308h-71.008v-25.308h71.008zm.775-.621h-72.403V442h72.403v-26.705zM364.961 504.26l.31 47.977H294.73l-.31-47.976h70.542zm.62-.776h-71.783l.31 49.219h71.783l-.31-49.219z'
                                fill='#A1A1A1'
                                fillRule='nonzero'
                                id='shape2'
                            />
                            <path
                                d='M315.504 415.295s15.504 8.694 26.201 0h-26.201z'
                                fill='#525252'
                                id='path29'
                            />
                            <path
                                d='M364.496 445.571v25.308h-71.008V445.57h71.008zm.775-.776h-72.403V471.5h72.403v-26.705z'
                                fill='#A1A1A1'
                                fillRule='nonzero'
                                id='shape3'
                            />
                            <path
                                d='M315.504 444.795s15.504 8.694 26.201 0h-26.201z'
                                fill='#525252'
                                id='path30'
                            />
                            <path
                                d='M364.496 474.76v25.308h-71.008v-25.307h71.008zm.775-.62h-72.403v26.705h72.403v-26.706z'
                                fill='#A1A1A1'
                                fillRule='nonzero'
                                id='shape4'
                            />
                            <path
                                d='M315.504 474.14s15.504 8.694 26.201 0h-26.201zM315.504 503.484s15.504 8.695 26.201 0h-26.201z'
                                fill='#525252'
                                id='path31'
                            />
                            <path
                                d='M281.55 398.371h367.132v10.713h-364.03z'
                                fill='#E6C9B3'
                                id='path32'
                            />
                            <path
                                d='M648.682 400.39c-4.03-.311-9.767.465-17.984 3.726-18.76 7.763-163.411 2.018-177.21.31-13.488-1.708-99.07 1.087-114.573 3.727-13.489 2.329-48.682-3.416-55.97-4.658l.31 1.242c5.117.931 19.38 3.26 33.024 4.347h23.876c17.985-2.64 100-5.279 113.178-3.57 7.132.93 32.093 2.95 78.915 3.57h51.938c23.1-.466 41.085-1.552 46.822-3.881 8.062-3.26 13.643-3.882 17.52-3.571l.154-1.243z'
                                fill='#DBBAA0'
                                id='path33'
                            />
                            <path
                                d='M315.349 402.874c9.922.31 39.07-.156 69.922-.621 43.256-.621 70.233-1.398 75.35-.31 9.301 2.018 149.457 4.812 166.976.93 6.977-1.552 13.488-3.105 18.605-4.502h-4.962c-4.186 1.087-8.837 2.174-13.953 3.26-17.365 3.882-157.21 1.087-166.512-.931-5.271-1.087-30.232-.466-75.659.155-30.852.466-60 .932-69.922.621-10.078-.31-16.434-1.708-20.62-3.105h-3.101c4.186 2.018 11.318 4.037 23.876 4.503z'
                                fill='#DBBAA0'
                                id='path34'
                            />
                            <path
                                d='M290.078 398.371v10.713h-72.869l.155-10.713z'
                                fill='#AB7F62'
                                id='path35'
                            />
                            <path
                                d='M226.667 405.668c-3.101-.931-6.357-.931-9.303.31v1.243c2.791-1.242 6.047-1.397 8.993-.466 1.395.466 2.79 1.242 3.876 2.33h1.86c-1.705-1.553-3.566-2.64-5.426-3.417zM255.194 402.408c1.705.155 3.41-.621 4.651-2.019.465-.62.93-1.242 1.085-2.018h-1.24a3.381 3.381 0 0 1-.775 1.242c-.93 1.087-2.326 1.553-3.721 1.553-2.016-.155-3.876-1.242-5.116-2.795h-1.55c1.55 2.329 3.875 3.726 6.666 4.037zM290.078 401.476c-3.411.932-6.822 3.26-10.853 5.9-.775.466-1.55 1.087-2.48 1.708h2.17c.31-.155.62-.466.93-.62 3.1-2.33 6.512-4.348 10.077-5.746l.156-1.242z'
                                fill='#967056'
                                id='path36'
                            />
                            <path
                                d='M217.364 399.303v1.242c6.357-1.708 16.745-3.26 22.946 1.552 8.372 6.366 18.45 11.024 31.628-3.726h-1.705c-11.938 12.887-21.086 9.005-29.148 2.64-1.705-1.243-3.72-2.174-5.736-2.64H221.24c-1.395.31-2.635.621-3.876.932zM281.705 398.371c-3.1 2.329-6.356 4.192-9.767 5.9-2.79 1.242-7.597 3.26-12.558 4.813h3.72c3.102-1.087 6.357-2.484 9.303-3.726 4.031-1.863 7.907-4.192 11.318-6.987h-2.016z'
                                fill='#967056'
                                id='path37'
                            />
                            <path
                                d='M217.364 403.495v1.242c8.372-2.64 16.28-.932 21.86 4.502h1.706c-5.891-6.365-14.418-8.539-23.566-5.744z'
                                fill='#967056'
                                id='path38'
                            />
                            <path
                                d='M588.992 350.705c-13.023.466-43.566 1.708-45.891 1.863-3.256.156-5.892 2.485-6.667 5.59l-11.163 39.903 59.69-.156s8.372-36.487 9.458-41.3c1.085-4.813-2.016-6.055-5.427-5.9z'
                                fill='url(#linearGradient-9)'
                                id='path39'
                            />
                            <path
                                d='M588.992 351.482c-13.023.465-43.566 1.707-45.891 1.707-2.946-.155-5.427 1.864-5.892 4.814-.93 3.415-11.162 39.902-11.162 39.902l58.914-.155s7.597-36.487 8.682-41.3c1.086-4.813-1.24-5.124-4.65-4.968z'
                                fill='#363636'
                                id='path40'
                            />
                            <path
                                d='M581.86 397.905l9.458-43.784-50.698 1.553-12.248 41.455z'
                                fill='#E8E8E8'
                                id='path41'
                            />
                            <path
                                d='M573.333 397.905l9.148-43.629-35.504 1.087-12.713 42.542z'
                                fill='#FCFCFC'
                                id='path42'
                            />
                            <path
                                d='M557.054 361.574v.62l4.031 4.348 1.086-.466z'
                                fill='#2B888F'
                                id='path43'
                            />
                            <path
                                d='M569.612 366.542v1.553s-1.395 3.726-8.992 3.726c-6.667 0-7.752-4.037-7.752-4.037v-1.552l16.744.31z'
                                fill='#6D6D6D'
                                id='path44'
                            />
                            <path
                                d='M557.054 361.574c1.24-.466 2.636-.621 4.031-.621 4.651 0 8.372 2.329 8.372 5.279 0 2.95-3.72 5.279-8.372 5.279-4.65 0-8.372-2.33-8.372-5.28h8.372l-4.03-4.657z'
                                fill='#909090'
                                id='path45'
                            />
                            <path
                                d='M559.69 365.455l-4.186-4.502-4.031 1.708-.465 2.328v.466z'
                                fill='#ED1C24'
                                id='path46'
                            />
                            <path
                                d='M551.008 365.455h8.528v.466h-8.528z'
                                fill='#A3141E'
                                id='rectangle15'
                                transform='rotate(-.092 555.272 365.688)'
                            />
                            <path
                                d='M483.876 397.905l1.085-1.552 93.644-.156 3.255 1.708z'
                                fill='#9E9E9E'
                                id='path47'
                            />
                            <path
                                d='M565.736 376.013l-.465 1.708h-13.643l.62-1.708zM564.651 379.584l-.465 1.708h-13.023l1.085-1.708zM563.566 383.155l-.465 1.708h-10.078l.62-1.708zM562.48 386.726l-.464 1.708h-11.783l.62-1.708zM561.395 390.297l-.465 1.708h-13.643l.62-1.708z'
                                fill='#C9C9C9'
                                id='path48'
                            />
                            <path
                                d='M464.186 392.16l-5.426 2.795-1.706 3.727h11.783z'
                                fill='#A9D2DE'
                                id='path49'
                            />
                            <path
                                d='M510.543 446.968s-55.814 3.106-65.582 5.9c-8.062 14.44-4.34 102.008-4.34 102.008l4.495.466s26.202-55.584 9.768-85.395c17.674 2.795 61.86-3.105 61.86-3.105l-6.201-19.874z'
                                fill='url(#linearGradient-10)'
                                id='path50'
                            />
                            <path
                                d='M490.078 444.795s-51.628 5.434-65.582 14.129c-8.062 14.44-24.03 95.02-24.03 95.02l2.945 1.709s38.605-44.406 31.783-80.271c33.023-3.26 59.845-8.54 59.845-8.54l-4.961-22.047z'
                                fill='#525252'
                                id='path51'
                            />
                            <path
                                d='M402.326 554.876c17.364-32.294 25.891-74.992 26.356-77.787l4.031-4.657-2.48-2.795 4.65-.466-1.24-.776c-1.55-.932-2.945-2.019-4.34-3.26 11.317-1.398 53.178-8.696 53.643-8.85l-.155-.932c-.465.155-44.962 7.918-54.574 8.85l-.93.155.62.776c1.24 1.242 2.636 2.33 4.031 3.416l-3.72.31 3.255 3.727-3.566 4.192v.155c-.155.466-8.527 44.405-26.357 77.632l.776.31z'
                                fill='#3F3F3F'
                                id='path52'
                            />
                            <path
                                d='M444.806 555.187v3.105h-11.938c0-.62.155-1.242.465-1.708 1.55-.931 4.807 0 7.442-1.863 2.946.466 4.031.466 4.031.466z'
                                fill='#473325'
                                id='path53'
                            />
                            <path
                                d='M430.853 371.2a85.518 85.518 0 0 0 14.418 8.85c3.101 1.397 4.341 2.018 7.752.621.775-2.795-12.403-8.384-22.17-9.471z'
                                fill='url(#linearGradient-11)'
                                id='path54'
                            />
                            <path
                                d='M458.605 350.084s-4.341 11.645.62 11.8c8.527.466 21.395-2.95 26.046 13.508-11.473 5.745-35.349 8.23-35.349 8.23-3.875-7.143-11.472-11.335-19.69-11.025-11.782-4.813-13.178-13.663-13.178-13.663-.31-3.105.31-6.21 1.706-9.005 2.17-4.813 6.976-10.713 12.558-20.184 0 0 8.682-4.813 17.52-1.398 1.24 2.174 2.79 4.192 4.495 6.056a342.646 342.646 0 0 0 5.272 15.681'
                                fill='#F5BA89'
                                id='path55'
                            />
                            <path
                                d='M417.674 360.332l5.737-.777.465.621-6.047 1.087z'
                                fill='#D69A67'
                                id='path56'
                            />
                            <path
                                d='M422.326 368.095s-4.186-4.037-4.342-4.348c4.342-.465 4.342-.31 7.132-1.552l2.946 2.484c-1.86 2.018-2.79 2.329-5.736 3.416z'
                                fill='#452A14'
                                id='path57'
                            />
                            <path
                                d='M439.69 369.337l-11.008-9.782-6.822-2.95-2.015-2.794-.62 1.086c-.155.621-.155 1.398-.155 2.019l-1.396-.155c-.155 2.794-1.705 1.397-1.705 1.397l-2.946-2.174-1.085-4.347 2.015-8.23-2.79-6.52 12.093-9.471 5.426-.466 3.1-3.105 6.047.31h3.721l8.372 3.882 7.597 14.44 3.876 6.83-1.705-2.018 4.651 15.216-6.667 5.124 2.946.621-20.93 1.087z'
                                fill='#452A14'
                                id='path58'
                            />
                            <g
                                id='group2'
                                stroke='#5B371B'
                                strokeWidth={1.2}
                                transform='translate(410.853 322.447)'
                            >
                                <path
                                    d='M40.465 32.295C29.922 18.165 5.116 31.985 0 24.532M20.31 5.745c5.892-.932 11.628-.777 15.194 2.64l4.186-2.95M11.628 26.55c-.31 2.018-1.24 4.037-2.48 5.745M21.085.776c.775 3.26-1.86 6.366-3.256 9.627M44.806 13.663c1.396 3.416 1.396 6.055-2.946 9.782M45.891 29.345C40.621 12.11-2.636 18.32 15.194 3.415'
                                    id='path59'
                                />
                            </g>
                            <path
                                d='M436.124 362.66c-1.705-2.173-1.085-6.52 1.705-7.142 2.791-.62 6.047 1.864 7.752 4.348.62 3.57-1.86 8.384-1.86 8.384s-5.892-3.416-7.597-5.59z'
                                fill='#E6A36C'
                                id='path60'
                            />
                            <path
                                d='M442.946 364.213c0-1.087-.62-2.174-1.706-2.64-.155 0-.31-.155-.31-.155s-.155-.155-.31-.155c-.31-.155-.465-.155-.775-.31-.31 0-.62-.156-.93 0-.155 0-.31 0-.465.155-.155 0-.31.155-.466.31l.62.156c-.154-.156-.154-.466-.154-.621 0-.156-.155-.466-.155-.621 0-.466 0-.777.155-1.087.155-.31.62-.621.93-.777.465-.155.93-.155 1.395-.31-.465-.155-.93-.155-1.395-.155-.465 0-1.085.31-1.396.776-.31.466-.465.932-.465 1.553 0 .31 0 .465.155.776 0 .31.155.466.155.776l.62.155H439.536c.155 0 .465.156.62.156.155 0 .31.155.31.155s.155.155.31.155c1.085.156 1.706.777 2.17 1.708z'
                                fill='#CC8E5E'
                                id='path61'
                            />
                            <path
                                d='M428.682 368.56l-4.03.622a25.33 25.33 0 0 0 7.906 4.657c.775-1.552-1.085-3.726-1.085-3.726l-2.79-1.552z'
                                fill='#FFF'
                                id='path62'
                            />
                            <path
                                d='M432.868 373.374c-2.015-2.019-4.34-3.571-7.132-4.348l-.465.466s.93 2.019 7.442 4.347l.155-.465z'
                                fill='url(#linearGradient-12)'
                                id='path63'
                            />
                            <path
                                d='M428.992 368.405l-5.891.777s3.876 2.484 5.891-.777z'
                                fill='#452A14'
                                id='path64'
                            />
                            <path
                                d='M430.853 367.94l.465.31 1.55-.776-.465-.466zM430.233 367.163l.93-1.863.31.466-.775 1.552z'
                                fill='#D69A67'
                                id='path65'
                            />
                            <path
                                d='M478.915 356.14l-11.628-10.714c-5.582.31-18.605 6.056-24.031 12.266l1.085 10.558s14.574-6.987 24.496-9.471l10.078-2.64z'
                                fill='#D6D6D6'
                                id='path66'
                            />
                            <path
                                d='M438.295 398.682h17.364v-3.571l-11.008-2.95-6.356 3.105z'
                                fill='#E6A36C'
                                id='path67'
                            />
                            <path
                                d='M444.031 395.576c3.566.777 6.977 1.708 10.233 2.95l-11.783-5.434 1.55 2.484z'
                                fill='#D6925F'
                                id='path68'
                            />
                            <path
                                d='M523.566 466.997c2.636-9.936 3.566-20.34 2.48-30.586-6.201-49.995-15.813-70.956-32.713-85.706-26.201-13.818-55.814 14.906-55.814 14.906 5.117 8.384 4.031 27.792 4.031 27.792s26.822 8.073 30.853 23.755c5.581 21.581 5.892 32.45 5.892 32.45s.155 8.229 8.217 15.837c8.062 7.608 37.054 1.552 37.054 1.552z'
                                fill='#EAEAEA'
                                id='path69'
                            />
                            <path
                                d='M400.62 553.945c-3.566 2.329-10.077 1.708-10.542 2.95-.31.466-.31.931-.31 1.552l14.728-.155-.775-2.795-3.1-1.552z'
                                fill='#473325'
                                id='path70'
                            />
                            <path
                                d='M439.535 363.747s-33.488 10.714-36.59 34.935h41.086l-.155-4.503-6.357-2.484 10.388 4.037c1.395-11.335-1.55-22.824-8.372-31.985z'
                                fill='#EAEAEA'
                                id='path71'
                            />
                            <path
                                d='M482.326 460.942c.155-.931.31-2.018.465-2.95'
                                id='path72'
                                stroke='#CCC'
                                strokeWidth={1.25}
                            />
                            <path
                                d='M483.41 451.937c2.792-28.413-2.945-53.876-31.937-71.732'
                                id='path73'
                                stroke='#CCC'
                                strokeDasharray='3.9859 3.9859'
                                strokeWidth={1.25}
                            />
                            <path
                                d='M448.837 378.653c-.775-.466-1.705-.932-2.635-1.398'
                                id='path74'
                                stroke='#CCC'
                                strokeWidth={1.25}
                            />
                            <path
                                d='M445.116 394.645c4.651-15.371.31-22.824-5.426-30.742'
                                id='path75'
                                stroke='#CCC'
                                strokeDasharray='3.8'
                                strokeWidth={1.25}
                            />
                            <path
                                d='M424.496 391.695l11.783 1.397 7.752 1.087-6.512-2.484z'
                                fill='#DBDBDB'
                                id='path76'
                            />
                            <path
                                d='M456.28 399.768l3.72 9.316-1.86 26.24 6.976 9.626 4.651-12.11-4.03-23.756-4.187-5.9c-1.395-1.552-3.1-2.64-5.27-3.416z'
                                fill='#C11F27'
                                id='path77'
                            />
                            <path
                                d='M499.38 472.276h4.496v42.697h-4.496z'
                                fill='#4A4A4A'
                                id='rectangle16'
                            />
                            <path
                                d='M497.209 501.621h8.837v42.697h-8.837z'
                                fill='#292929'
                                id='rectangle17'
                            />
                            <path
                                d='M458.14 551.616c0-2.64-2.171-4.813-4.807-4.813-2.635 0-4.806 2.173-4.806 4.813 0 2.64 2.17 4.813 4.806 4.813s4.807-2.174 4.807-4.813zM551.318 551.616c0-2.64-1.55-4.813-3.411-4.813-1.86 0-3.41 2.173-3.41 4.813 0 2.64 1.55 4.813 3.41 4.813s3.41-2.174 3.41-4.813z'
                                fill='#292929'
                                id='path78'
                            />
                            <path
                                d='M522.79 551.616c0-2.64-1.55-4.813-3.41-4.813a12.547 12.547 0 0 0-3.411 0c-1.395.62 0 2.64 0 4.813 0 2.173-1.395 4.192 0 4.813h3.41c1.861-.155 3.412-2.174 3.412-4.813z'
                                fill='#404040'
                                id='path79'
                            />
                            <path
                                d='M519.38 551.616c0-2.64-1.55-4.813-3.411-4.813-1.86 0-3.41 2.173-3.41 4.813 0 2.64 1.55 4.813 3.41 4.813s3.41-2.174 3.41-4.813zM448.682 546.803h103.256l-2.48-4.037-96.745-1.863z'
                                fill='#292929'
                                id='path80'
                            />
                            <path
                                d='M497.21 541.834h15.503l3.101 4.969h-18.605z'
                                fill='url(#linearGradient-13)'
                                id='path81'
                            />
                            <path
                                d='M512.713 541.834l6.047.31 4.186 4.659h-7.132z'
                                fill='#0A0A0A'
                                id='path82'
                            />
                            <path
                                d='M549.922 462.184c0 6.832-22.48 12.266-50.232 12.266s-50.078-5.434-50.078-12.266c0-6.831 23.411-2.795 51.163-2.795 27.752 0 49.147-4.036 49.147 2.795z'
                                fill='url(#linearGradient-14)'
                                id='path83'
                            />
                            <path
                                d='M469.457 400.855c-.155 7.453 5.737 13.819 13.179 13.974l64.806 1.863c7.442.155 13.798-5.745 13.953-13.197l2.016-24.532c.155-7.452-7.132-15.06-14.574-15.06h-64.806c-7.597 0-13.643 6.055-13.953 13.663l-.62 23.29z'
                                fill='#474747'
                                id='path84'
                            />
                            <path
                                d='M515.194 396.974l-1.55 3.105-2.946 1.242v7.297l1.86-.62v7.763l9.768.155-2.636-18.942z'
                                fill='#212121'
                                id='path85'
                            />
                            <path
                                d='M494.109 474.45l3.1 2.95h15.194l2.79-3.416z'
                                fill='#2B2B2B'
                                id='path86'
                            />
                            <path
                                d='M501.705 474.45s9.303-7.608 10.698-26.705c1.395-19.098 2.79-50.771 2.79-50.771h12.249s-.62 38.815-1.86 50.15c-2.016 20.029-15.194 28.568-15.194 28.568l-8.683-1.242z'
                                fill='#3D3D3D'
                                id='path87'
                            />
                            <path
                                d='M513.178 398.526h16.124v8.074h-16.124z'
                                fill='#2B2B2B'
                                id='rectangle18'
                            />
                            <path
                                d='M529.767 400.079v4.347l1.706-.31.31-3.416z'
                                fill='#2B2B2B'
                                id='path88'
                            />
                            <path
                                d='M484.031 363.903c-7.442.155-13.488 6.21-13.798 13.663l-.62 23.134c-.156 7.297 5.426 13.508 12.713 13.974-4.497-1.553-8.373-4.813-10.698-9.006-1.24-2.484-1.706-19.097 0-29.5 1.705-10.402 12.403-12.265 12.403-12.265z'
                                fill='#45413D'
                                id='path89'
                            />
                            <path
                                d='M519.877 410.768h1.398l-.001 12.713h-1.398z'
                                fill='#292929'
                                id='rectangle19'
                                transform='rotate(-86.977 520.575 417.125)'
                            />
                            <path
                                d='M513.643 427.561h13.023v1.397h-13.023z'
                                fill='#292929'
                                id='rectangle20'
                            />
                            <path
                                d='M519.025 430.649h1.397l-.001 13.178h-1.397z'
                                fill='#292929'
                                id='rectangle21'
                                transform='rotate(-86.636 519.723 437.238)'
                            />
                            <path
                                d='M512.54 443.854l13.334-.001v1.397l-13.333.001z'
                                fill='#292929'
                                id='rectangle22'
                                transform='rotate(-3.102 519.207 444.552)'
                            />
                            <path
                                d='M511.618 453.018h12.868v1.397h-12.868z'
                                fill='#292929'
                                id='rectangle23'
                                transform='rotate(-1.775 518.052 453.717)'
                            />
                            <path
                                d='M514.588 456.703h1.397l-.001 11.472h-1.398z'
                                fill='#292929'
                                id='rectangle24'
                                transform='rotate(-84.013 515.286 462.44)'
                            />
                            <path
                                d='M357.054 557.36H337.83l-29.457-45.181 12.558-2.795z'
                                fill='#707070'
                                id='path90'
                            />
                            <path
                                d='M323.41 557.36h-12.557s-2.326-59.155 1.55-59.155c3.876 0 11.008 59.156 11.008 59.156z'
                                fill='#404040'
                                id='path91'
                            />
                            <path
                                d='M310.853 557.36h-5.892l-.775-59.155 1.24-.62z'
                                fill='#A3141E'
                                id='path92'
                            />
                            <path
                                d='M283.72 496.963h-15.503l-2.946 60.087h18.45z'
                                fill='#363636'
                                id='path93'
                            />
                            <path
                                d='M283.721 496.963h12.403v60.087h-12.403z'
                                fill='#5D5D5D'
                                id='rectangle25'
                            />
                            <path
                                d='M296.124 496.963h8.837v60.087h-8.837z'
                                fill='#CFCFCF'
                                id='rectangle26'
                            />
                            <path
                                d='M317.52 556.74l-6.667.62-5.427-59.776 6.667-.62z'
                                fill='#5D5D5D'
                                id='path94'
                            />
                            <path
                                d='M314.884 554.721l-.775-.155-2.946-33.071h.775z'
                                fill='#FFF'
                                id='path95'
                            />
                            <path
                                d='M330.078 556.118l-6.667 1.243-11.008-59.156 6.667-1.242z'
                                fill='#A1A1A1'
                                id='path96'
                            />
                            <path
                                d='M360.62 554.721l-3.566 2.64-36.124-47.977 3.566-2.64z'
                                fill='#CFCFCF'
                                id='path97'
                            />
                            <path
                                d='M288.062 501h3.721v31.053h-3.721zM288.062 535.468h3.721v5.279h-3.721zM298.76 501h3.721v13.508h-3.721zM298.76 516.992h3.721V530.5h-3.721z'
                                fill='#FFF'
                                id='rectangle27'
                            />
                            <path
                                d='M323.41 533.295l-2.015.155-5.891-32.76 2.015-.156zM324.496 514.042l3.256-2.95 1.55 2.174-2.79 3.26zM330.078 518.545l.465-.466 11.473 14.44-.62.62z'
                                fill='#FFF'
                                id='path98'
                            />
                            <path
                                d='M227.442 347.6l53.953 2.018c2.481.156 4.496 1.864 4.962 4.348.775 2.64 12.403 44.25 12.403 44.25h-63.876s-9.768-43.94-10.388-46.424c-.62-2.484.465-4.192 2.946-4.192z'
                                fill='#C2C2C2'
                                id='path99'
                            />
                            <path
                                d='M237.054 396.353l-10.387-47.2 55.969 2.329 12.868 44.87z'
                                fill='#FFF'
                                id='path100'
                            />
                            <path
                                d='M343.1 398.06l-1.24-1.707H237.83l-3.566 1.708z'
                                fill='#DBDBDB'
                                id='path101'
                            />
                            <path
                                d='M282.946 381.603h-41.55l-.156-.466h41.706zM281.085 373.84H239.38v-.311h41.55zM279.07 366.232h-41.55l-.156-.31h41.706z'
                                fill='#DEDEDE'
                                id='path102'
                            />
                            <path
                                d='M236.28 361.729l6.976 27.326h41.55l-2.635-10.868-6.822-9.782-3.411 4.503-7.442 2.64-10.232-9.472z'
                                fill='#A50008'
                                id='path103'
                                opacity={0.2}
                            />
                            <path
                                d='M277.21 358.624h-41.706v-.31h41.55z'
                                fill='#DEDEDE'
                                id='path104'
                            />
                            <path
                                d='M284.961 389.21h-41.705v-.31h41.55z'
                                fill='#A1A1A1'
                                id='path105'
                            />
                            <path
                                d='M281.705 378.497l-6.201-9.16-3.256 4.037v.155l-7.752 2.64-10.387-9.627-17.83-4.347v-.932l18.14 4.348 10.077 9.315 6.977-2.484 3.876-4.968 7.287 10.558z'
                                fill='#A82700'
                                id='path106'
                            />
                            <path
                                d='M240.93 379.895l9.303-4.348 10.852 8.074 7.907-21.582h3.566l3.876-6.365 8.372 33.381h-41.55z'
                                fill='#A6BBBC'
                                id='path107'
                                opacity={0.25}
                            />
                            <path
                                d='M261.395 384.397l-11.162-8.229-8.993 4.193-.62-.932 9.613-4.503 10.542 7.763 7.752-21.115h3.721l3.721-6.21.93.62-3.876 6.677h-3.566z'
                                fill='#4D4D4D'
                                id='path108'
                            />
                            <path
                                d='M249.767 390.297h-.31l-.31-1.242h.31zM259.69 390.297h-.31l-.31-1.242h.31zM269.612 390.297h-.31l-.31-1.242h.31zM279.535 390.297h-.31l-.31-1.242h.31z'
                                fill='#A1A1A1'
                                id='path109'
                            />
                            <path
                                d='M317.52 378.497h-14.73l.776 20.185h13.488z'
                                fill='#1C1C1C'
                                id='path110'
                            />
                            <path
                                d='M304.341 395.732c-2.015.155-3.876-.621-5.271-2.019-1.396-1.552-1.86-3.881-1.55-7.142.154-1.553.775-2.95 2.015-3.882 1.24-.931 2.946-1.397 4.496-1.086l-.31 2.484.155-1.242-.155 1.242c-.93-.155-1.86.155-2.636.62-.62.467-.93 1.243-.93 2.02-.31 2.484 0 4.191.93 5.278.93.932 2.17 1.242 3.411 1.242v2.485h-.155z'
                                fill='#1C1C1C'
                                id='path111'
                            />
                            <path
                                d='M329.302 378.808h-14.418l.775 19.874h13.178z'
                                fill='#ED1C24'
                                id='path112'
                            />
                            <path
                                d='M316.434 395.732c-2.015.155-3.876-.621-5.271-2.019-1.396-1.552-1.86-3.881-1.55-6.987.154-1.397.775-2.794 1.86-3.726 1.24-.932 2.946-1.242 4.496-1.087l-.31 2.484.155-1.242-.155 1.242c-.93-.155-1.86.156-2.636.621-.62.466-.93 1.243-.93 2.019-.31 2.484 0 4.192.93 5.124.93.931 2.17 1.242 3.411 1.086v2.485z'
                                fill='#ED1C24'
                                id='path113'
                            />
                            <image
                                height={8}
                                id='error'
                                width={39}
                                x={468}
                                xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAICAYAAAB6WebWAAAABGdBTUEAALGOfPtRkwAAAcNJREFUOBGtkztIQlEch/ORVGCL5JIhRATR0OALdXG2NaFAsKmglAoqaCyiiFpcoyDaIkLIwaEoEUK4KRhtTc0aLUKGoPad8MQl6yrRgcP/+fvOi6Prag6XyzVXr9eXCAd1Op2i1+ujiqI8ybqwDofjAPPGNNIzkcvlJkVeDK2aqMNfgL+IzkZYwK7eM0RNju8MvSg4nc75RqOxxYaWTCbTOKk0oIzf7zdLobACzFzD7cPudVqDH4W3B38fTYD5wHoZNjysxTCKIo0bCOMc5LrZvAMwUq1WZ4gPmzlpzvP5/LIMvtnfauv0ncI/Ef2sV4A/hRtjroicanwxjIFAoKdcLts52TrX+tUIoB/BmEok3Yp0frAtNa/X28shhwwGgzy4eIEGa92gH9ViGNPp9DuNRQTi5nbVzQKijv/iZ7PZCvyXWq3mRp9QMUR8q4pb3M9nJXvMjHk8nnwwGLxKpVID3OQ0uXiL4m+JI2QRt9t9YbFYHkul0iwvM0IurIX7/BBWq3WT5jNOd5lMJl+xz8R2nlxuXovRtmY2mzdpSsC9KxaLFdjbxGF+u9JWLBu4/m6fz2cPhUIGmftPC9fE69g6ZX4AUHu6/DUtjO0AAAAASUVORK5CYII='
                                y={277}
                            />
                            <g id='lamp' transform='translate(646 4)'>
                                <path
                                    d='M200.887 61.818l-161.2 38.724-2.692 3.122L203 63.964zM92.014 3.804l1.804 12.364h6.614l2.965-8.59z'
                                    fill='#3D3D3D'
                                    id='path114'
                                />
                                <path
                                    d='M59.762 0s18.466 7.608 38.086 7.608C117.468 7.608 129.01 0 129.01 0H59.762z'
                                    fill='#636363'
                                    id='path115'
                                />
                                <path
                                    d='M87.271 16.02l112.941 51.504 2.788-.961-115.729-54.2z'
                                    fill='#4A4A4A'
                                    id='path116'
                                />
                                <path
                                    d='M100.551 18.545a3.324 3.324 0 0 1-3.32 3.33 3.324 3.324 0 0 1-3.32-3.33 3.324 3.324 0 0 1 3.32-3.328c1.834 0 3.32 1.49 3.32 3.328zM199.206 64.196a3.324 3.324 0 0 1-3.32 3.328 3.324 3.324 0 0 1-3.32-3.328 3.324 3.324 0 0 1 3.32-3.329c1.833 0 3.32 1.49 3.32 3.329z'
                                    fill='#636363'
                                    id='path117'
                                />
                                <path
                                    d='M34.158 107.075s-.45-9.117 5.246-9.117c5.697 0 2.25 10.462 2.25 10.462l-7.496-1.345z'
                                    fill='#3D3D3D'
                                    id='path118'
                                />
                                <path
                                    d='M40.79 101.762a1.9 1.9 0 0 1-1.897 1.902 1.9 1.9 0 0 1-1.898-1.902 1.9 1.9 0 0 1 1.898-1.902 1.9 1.9 0 0 1 1.897 1.902z'
                                    fill='#292929'
                                    id='path119'
                                />
                                <path
                                    d='M0 115.049s15.085-18.676 36.756-10.069C58.427 113.588 56.892 136 56.892 136L0 115.049z'
                                    fill='#454545'
                                    id='path120'
                                />
                                <path
                                    d='M8.537 118.043s11.697-13.637 26.953-13.425l1.505.59c-8.143 2.745-14.674 8.767-17.921 16.526-4.682-1.664-10.537-3.691-10.537-3.691z'
                                    fill='#545454'
                                    id='path121'
                                />
                            </g>
                            <g id='group3' transform='translate(373 158)'>
                                <rect
                                    fill='#ED1B25'
                                    height={64}
                                    id='rectangle28'
                                    rx={3}
                                    width={135}
                                    x={0}
                                    y={0}
                                />
                                <rect
                                    fill='#000'
                                    height={58}
                                    id='rectangle29'
                                    rx={2}
                                    width={129}
                                    x={3}
                                    y={3}
                                />
                                <text fill='#00CE08' fontFamily='alarm' fontSize={42}>
                                    <tspan x={24.875} y={45}>
                                        {this.props.hour}
                                        <tspan>:</tspan>
                                        {this.props.minutes}
                                    </tspan>
                                </text>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
